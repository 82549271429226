import React, { useState } from 'react';
import SearchBox from './styled/SearchBox';

const SearchAndCreateBox = ({ onSubmit, onSearch }) => {
  const ENTER = 'Enter';
  const [value, setValue] = useState('');
  const searchBoxRef = React.useRef();

  const onEnterKey = (event) => {
    if (event.key !== ENTER) return;

    onSubmit(value);
  };

  const onChange = ({ target: { value } }) => {
    setValue(value);
    onSearch(value);
  };

  React.useEffect(() => {
    setTimeout(() => {
      searchBoxRef.current.focus();
    }, 0);
  }, []);

  return (
    <SearchBox
      placeholder="Find or create"
      onKeyUp={onEnterKey}
      value={value}
      onChange={onChange}
      autoFocus={true}
      ref={searchBoxRef}
    ></SearchBox>
  );
};

export default SearchAndCreateBox;
