import tw, { styled } from 'twin.macro';
import Card from './Card';

const TaskCard = styled(Card)`
  ${tw`relative`}
  cursor: grab;

  ${({ isTransparent }) => isTransparent && tw`opacity-50`}

  ${({ displayClassNameOnHover }) =>
    displayClassNameOnHover &&
    `
      .${displayClassNameOnHover} {
        display: inline;
      }

      @media (min-width: 640px) {
        .${displayClassNameOnHover} {
          display: none;
        }

        &:hover {
          .${displayClassNameOnHover} {
            display: inline;
          }
        }
      }
    `}

  &:hover {
    ${tw`opacity-100`}
  }
`;

export default TaskCard;
