import tw from 'twin.macro';

const Card = tw.div`
  max-w-sm
  rounded
  overflow-visible
  shadow-lg
  m-2 mb-5
  bg-card-white
  border border-solid border-gray-200
  rounded-xl
`;

export default Card;
