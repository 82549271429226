export const secondsToTimeLong = (e) => {
  const h = Math.floor(e / 3600);
  const m = Math.floor((e % 3600) / 60);
  let hrs;

  if (h > 1) {
    hrs = ' hrs ';
  } else {
    hrs = ' hr ';
  }

  if (h && m) {
    return h + hrs + m + ' mins';
  } else if (h) {
    return h + hrs;
  } else if (m) {
    return m + ' mins';
  }
};

export const secondsToTimeShort = (e) => {
  var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0');

  return h + ':' + m;
};

export const simplifyDate = (date) => {
  return date.toLocaleDateString('en-US');
};

export const travelWeeks = (date, toTravel) => {
  return new Date(date.getTime() + toTravel * 7 * 24 * 60 * 60 * 1000);
};
