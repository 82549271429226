import React from 'react';
import { popRedirectTo } from '../utilities/auth';
import { Redirect } from 'react-router';

const Home = () => {
  const redirectTo = popRedirectTo();

  if (redirectTo) {
    return <Redirect to={{ pathname: redirectTo }}></Redirect>;
  } else {
    return <h1>Home</h1>;
  }
};

export default Home;
