import React from 'react';
import Link from './styled/Link';
import { login } from '../services/api';
import 'twin.macro';

const LoginButton = ({ children }) => {
  return <Link onClick={login}>{children || 'Log In'}</Link>;
};

export default LoginButton;
