import React from 'react';
import tw from 'twin.macro';

const StyledOverlay = tw.div`
  fixed
  top-0
  bottom-0
  left-0
  right-0
  bg-black
  opacity-50
  z--1
`;

const Overlay = ({ onClick }) => {
  return <StyledOverlay onClick={onClick}></StyledOverlay>;
};

export default Overlay;
