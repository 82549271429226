import tw from 'twin.macro';

const Button = tw.button`
  py-1 px-2
  mb-1
  w-full max-w-sm

  text-xs
  text-gray-500
  bg-card-white

  rounded
  border border-solid border-gray-200
  shadow-md

  cursor-pointer

  focus:outline-none
  focus:border
  focus:border-solid
  focus:border-gray-500
`;

export default Button;
