import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import tw from 'twin.macro';
import 'styled-components/macro';

const SelectableLink = ({ to, ...props }) => {
  const isSelected = props.location.pathname === to;

  return (
    <Link
      to={to}
      css={[
        tw`opacity-50`,
        isSelected && tw`opacity-100 text-blue2 font-medium sm:font-semibold`,
      ]}
    >
      {props.children}
    </Link>
  );
};

export default withRouter(SelectableLink);
