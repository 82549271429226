import React from 'react';
import { Route } from 'react-router-dom';
import { isAuthenticated, saveRedirectTo } from '../utilities/auth';
import { login } from '../services/api';
import Loading from './Loading';

const ProtectedRoute = ({ component, ...args }) => {
  if (!isAuthenticated()) {
    saveRedirectTo();
    login();
    return <Loading></Loading>;
  }

  return <Route component={component} {...args} />;
};

export default ProtectedRoute;
