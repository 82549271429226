import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { updateTask } from '../services/api';
import { debounce } from 'lodash';
import CardSection from './styled/CardSection';
import DurationMenu from './DurationMenu';
import CategoryMenu from './CategoryMenu';
import Justified from './styled/Justified';
import Small from './styled/Small';
import Pill from './styled/Pill';
import TaskCard from './styled/TaskCard';
import Textarea from './styled/Textarea';
import Pointer from './styled/Pointer';
import Icon from './styled/Icon';
import { secondsToTimeShort } from '../utilities/time';
import useOuterClick from '../utilities/hooks/useOuterClick';

import tw from 'twin.macro';

import {
  faTrashAlt,
  faCheckCircle as faRegularCheckCircle,
  faClock,
} from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle as faSolidCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Task = ({
  task: {
    id,
    title: initialTitle,
    completed: initialCompleted,
    duration: initialDuration,
    category: { title: initialCategoryTitle } = {},
  },
  textareaRef,
  ...props
}) => {
  const durationMenuRef = useOuterClick((event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    const clickedOnTrigger =
      textDurationRef.current === event.target ||
      iconDurationRef.current === event.target ||
      path.includes(iconDurationRef.current);

    if (clickedOnTrigger) return;

    toggleDurationMenu();
  });
  const categoryMenuRef = useOuterClick((event) => {
    const clickedOnTrigger = categoryMenuTriggerRef.current === event.target;

    if (clickedOnTrigger) return;

    toggleCategoryMenu();
  });
  const [isDurationMenuOpen, setIsDurationMenuOpen] = useState(false);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [duration, setDuration] = useState(initialDuration);
  const [categoryTitle, setCategoryTitle] = useState(initialCategoryTitle);
  const [completed, setCompleted] = useState(initialCompleted);
  const [title, setTitle] = useState(initialTitle);
  const [updateTaskMutation] = useMutation(() => updateTask(id, { title }));
  const [toggleTaskCompletion] = useMutation(
    () =>
      updateTask(id, {
        completed: !completed,
        completed_at: !completed ? new Date() : null,
      }),
    {
      onSuccess: ({ completed }) => {
        setCompleted(completed);
        props.onTaskComplete(id, completed);
      },
      onError: () => alert('There was an error completing this task.'),
    }
  );

  const onTitleChange = async ({ target: { value } }) => {
    setTitle(value);
    updateTitle();
    props.onTitleChange(value, id);
  };

  const updateTitle = useCallback(
    debounce(
      async () => {
        await updateTaskMutation();
      },
      200,
      { leading: true }
    ),
    []
  );

  const confirmTaskRemove = () => {
    if (title) {
      window.confirm('Are you sure?') && props.onRemoveTask(id);
    } else {
      props.onRemoveTask(id);
    }
  };

  const removeTaskIfEmpty = () => {
    !title && props.onRemoveTask(id);
  };

  const toggleDurationMenu = (source) => {
    setIsDurationMenuOpen(!isDurationMenuOpen);
  };

  const toggleCategoryMenu = () => {
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
  };

  const renderNewDuration = ({ duration }) => {
    setDuration(duration);
    props.setDuration(id, duration);
    toggleDurationMenu();
  };

  const renderCategoryAfterUpdate = ({ title: categoryTitle } = {}) => {
    setCategoryTitle(categoryTitle);
    toggleCategoryMenu();
  };

  const renderCategoryAfterDeletion = ({ title: newCategoryTitle }) => {
    if (categoryTitle === newCategoryTitle) {
      setCategoryTitle(null);
    }
  };

  const hasNoDuration = duration === 0;
  const hasDuration = duration > 0;

  const taskRef = React.useRef();
  const textDurationRef = React.useRef();
  const iconDurationRef = React.useRef();
  const durationTriggerRef = React.useRef();
  const categoryMenuTriggerRef = React.useRef();

  return (
    <TaskCard
      as="li"
      displayClassNameOnHover="js-display-me-on-hover"
      isTransparent={completed}
      ref={taskRef}
    >
      <header>
        <CardSection>
          <Justified>
            <Textarea
              ref={textareaRef}
              placeholder="What do you want to do?"
              value={title || ''}
              onFocus={() => props.onTaskFocus(title)}
              onBlur={removeTaskIfEmpty}
              onChange={onTitleChange}
              tw="w-11/12"
            ></Textarea>
            <Small
              size="xs"
              onClick={() => toggleDurationMenu()}
              css={[tw`text-right`]}
              tw="w-10 h-5"
              ref={durationTriggerRef}
            >
              {hasDuration ? (
                <Pointer ref={textDurationRef}>
                  {secondsToTimeShort(duration)}
                </Pointer>
              ) : (
                ''
              )}
              {title && hasNoDuration && (
                <Icon
                  icon={faClock}
                  onClick={() => toggleDurationMenu()}
                  size="small"
                  color="gray"
                  margin="left"
                  vAlign="bottom"
                  className="js-display-me-on-hover"
                  hidden
                  ref={iconDurationRef}
                ></Icon>
              )}
            </Small>
          </Justified>
        </CardSection>
      </header>
      <footer>
        <CardSection>
          <Justified>
            <Pill onClick={toggleCategoryMenu} ref={categoryMenuTriggerRef}>
              {categoryTitle || 'No Category'}
            </Pill>
            <Pill last>No Goal</Pill>
          </Justified>
        </CardSection>
        <CardSection>
          <Justified>
            <div>
              {title && (
                <Icon
                  icon={completed ? faSolidCheckCircle : faRegularCheckCircle}
                  onClick={toggleTaskCompletion}
                  size="small"
                  color={completed ? 'green' : 'gray'}
                  colorOnHover="green"
                ></Icon>
              )}
            </div>
            <div>
              <Icon
                className={title && 'js-display-me-on-hover'}
                icon={faTrashAlt}
                onClick={confirmTaskRemove}
                margin="left"
                size="small"
                color="gray"
                colorOnHover="red"
                hidden={!!title}
              ></Icon>
            </div>
          </Justified>
        </CardSection>

        {isDurationMenuOpen && (
          <DurationMenu
            taskId={id}
            triggerElementRef={durationTriggerRef}
            containerElementRef={taskRef}
            ref={durationMenuRef}
            onDurationUpdate={renderNewDuration}
          ></DurationMenu>
        )}

        {isCategoryMenuOpen && (
          <CategoryMenu
            taskId={id}
            triggerElementRef={categoryMenuTriggerRef}
            containerElementRef={taskRef}
            ref={categoryMenuRef}
            onCategoryUpdate={renderCategoryAfterUpdate}
            onCategoryDelete={renderCategoryAfterDeletion}
          ></CategoryMenu>
        )}
      </footer>
    </TaskCard>
  );
};

export default Task;
