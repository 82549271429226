import React from 'react';
import tw, { css } from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = React.forwardRef(
  (
    { color, margin, size = 'small', hidden, colorOnHover, vAlign, ...props },
    ref
  ) => {
    const colors = {
      gray: tw`text-gray-500`,
      green: tw`text-green-500`,
      red: tw`text-red-500`,
    };

    const margins = {
      left: tw`ml-2`,
    };

    const sizes = {
      small: tw`text-sm`,
      normal: tw`text-base`,
    };

    const verticalAlignments = {
      bottom: tw`align-bottom`,
    };

    const hoverStyles = css`
      &:hover {
        ${colors[colorOnHover]}
      }
    `;

    const styles = [
      tw`cursor-pointer`,
      colors[color],
      margins[margin],
      sizes[size],
      hidden && tw`hidden`,
      colorOnHover && hoverStyles,
      verticalAlignments[vAlign],
    ];

    return (
      <FontAwesomeIcon
        css={styles}
        forwardedRef={ref}
        {...props}
      ></FontAwesomeIcon>
    );
  }
);

export default Icon;
