import { getCookie } from 'react-use-cookie';

export const isAuthenticated = () => {
  const userId = getCookie('user_id');

  return !!userId;
};

const NAMESPACE = process.env.REACT_APP_ENV;

export const saveRedirectTo = () => {
  const to = window.location.pathname;

  localStorage.setItem(`${NAMESPACE}_redirectTo`, to);

  return to;
};

export const popRedirectTo = () => {
  const to = localStorage.getItem(`${NAMESPACE}_redirectTo`);

  localStorage.removeItem(`${NAMESPACE}_redirectTo`);
  return to;
};
