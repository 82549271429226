import tw, { styled } from 'twin.macro';

const colors = {
  indigo: tw`bg-indigo-600 text-white`,
  teal: tw`bg-teal-600 text-white`,
  blue: tw`bg-blue-600 text-white`,
  purple: tw`bg-purple-600 text-white`,
  pink: tw`bg-pink-600 text-white`,
  red: tw`bg-red-600 text-white`,
  orange: tw`bg-orange-600 text-white`,
  yellow: tw`bg-yellow-600 text-white`,
  green: tw`bg-green-600 text-white`,
  default: tw`bg-gray-200 text-gray-700`,
};

const Pill = styled.div`
  ${tw`cursor-pointer`}
  ${tw`inline-block`}
  ${tw`rounded-full`}
  ${tw`px-2 sm:px-3 sm:py-1`}
  ${tw`mr-2`}
  ${({ last }) => last && tw`mr-0`}
  ${tw`truncate`}
  ${tw`text-xs sm:text-sm`}
  ${tw`font-semibold`}
  ${({ color }) => colors[color] || colors['default']}

`;

export default Pill;
