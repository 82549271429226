import { useRef, useEffect } from 'react';

// https://stackoverflow.com/a/54292872/8297355
const useOuterClick = (callback) => {
  const innerRef = useRef();
  const callbackRef = useRef();

  // Set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e) => {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);

    // Read most recent callback and innerRef dom node from refs
  }, []); // No need for callback + innerRef dep

  return innerRef; // Return ref; client can omit `useRef`
};

export default useOuterClick;
