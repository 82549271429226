import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TaskIndex from './components/TaskIndex';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';

const App = () => {
  return (
    <Router>
      <Navigation></Navigation>
      <Switch>
        <ProtectedRoute path="/tasks" component={TaskIndex} />
        <Route path="/weekly_goals">
          <h1>Weekly Goals</h1>
        </Route>
        <Route path="/">
          <Home></Home>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
