import React from 'react';
import tw from 'twin.macro';

const CompletionDisplay = ({ objects }) => {
  const completionWidth = () => {
    if (!objects.length) return tw`w-0`;

    const completed = objects.filter((object) => object.completed);
    const rate = completed.length / objects.length;

    if (rate < 1e-10) {
      return tw`w-0`;
    } else if (rate >= 1e-10 && rate < 0.09090909090909091) {
      return tw`w-1/12`;
    } else if (rate >= 0.09090909090909091 && rate < 0.18181818181818182) {
      return tw`w-2/12`;
    } else if (rate >= 0.18181818181818182 && rate < 0.2727272727272727) {
      return tw`w-3/12`;
    } else if (rate >= 0.2727272727272727 && rate < 0.36363636363636365) {
      return tw`w-4/12`;
    } else if (rate >= 0.36363636363636365 && rate < 0.45454545454545453) {
      return tw`w-5/12`;
    } else if (rate >= 0.45454545454545453 && rate < 0.5454545454545454) {
      return tw`w-6/12`;
    } else if (rate >= 0.5454545454545454 && rate < 0.6363636363636364) {
      return tw`w-7/12`;
    } else if (rate >= 0.6363636363636364 && rate < 0.7272727272727273) {
      return tw`w-8/12`;
    } else if (rate >= 0.7272727272727273 && rate < 0.8181818181818182) {
      return tw`w-9/12`;
    } else if (rate >= 0.8181818181818182 && rate < 0.9090909090909091) {
      return tw`w-10/12`;
    } else if (rate >= 0.9090909090909091 && rate < 1) {
      return tw`w-11/12`;
    } else if (rate === 1) {
      return tw`w-full`;
    }
  };

  return (
    <div css={completionWidth()} tw="mt-2">
      <div tw="bg-green-300 h-2"></div>
    </div>
  );
};

export default CompletionDisplay;
