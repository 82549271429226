import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import Card from './Card';

const sizes = {
  small: tw`w-4/12`,
  medium: tw`w-6/12`,
  large: tw`w-11/12`,
};

const roundnessSides = {
  left: [tw`rounded-tl-xl`, tw`rounded-br-xl`],
  right: [tw`rounded-tr-xl`, tw`rounded-bl-xl`],
};

const StyledFloatingMenuCard = styled(Card)`
  ${tw`max-h-21r`}
  ${tw`overflow-auto`}
  ${tw`absolute`}
  ${tw`z-10`}
  ${({ size }) => sizes[size]}
  ${tw`ml-0 mr-0 mt-0`}
  ${tw`rounded-tr-none`}
  ${tw`rounded-tl-none`}
  ${tw`shadow-xl`}
  ${({ roundnessSide }) => roundnessSides[roundnessSide]}
`;

const MEDIUM = 'medium';
const LARGE = 'large';

const FloatingMenuCard = React.forwardRef(
  (
    { children, containerElementRef, triggerElementRef, size = MEDIUM },
    ref
  ) => {
    const LEFT = 'left';
    const RIGHT = 'right';
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [side, setSide] = useState();

    useEffect(() => {
      const triggerElementBounds = triggerElementRef?.current?.getBoundingClientRect();
      const containerElementBounds = containerElementRef?.current?.getBoundingClientRect();
      const selfElementBounds = ref?.current?.getBoundingClientRect();
      const isOnRightPartOfContainer =
        (size === LARGE
          ? triggerElementBounds.right / 2
          : triggerElementBounds.right) >
        containerElementBounds.right - containerElementBounds.width / 2;

      if (isOnRightPartOfContainer) {
        setSide(LEFT);
        setLeft(
          containerElementBounds.width -
            selfElementBounds.width +
            triggerElementBounds.right -
            containerElementBounds.right
        );
      } else {
        setSide(RIGHT);
        setLeft(triggerElementBounds.left - containerElementBounds.left);
      }

      setTop(triggerElementBounds.bottom - containerElementBounds.top);
    }, [containerElementRef, triggerElementRef, ref, size]);

    return (
      <StyledFloatingMenuCard
        size={size}
        ref={ref}
        roundnessSide={side}
        style={{
          visibility: !left && !top ? 'hidden' : 'visible',
          left: `${left}px`,
          top: `${top + 5}px`,
        }}
      >
        {children}
      </StyledFloatingMenuCard>
    );
  }
);

export default FloatingMenuCard;
