import React from 'react';
import 'twin.macro';
import BurgerIcon from '../../assets/images/menu.svg';

const BurgerMenu = ({ onClick }) => {
  return (
    <img
      onClick={onClick}
      src={BurgerIcon}
      alt="Menu"
      tw="cursor-pointer
          opacity-50 hover:opacity-100
          min-w-1.5r
          max-w-1.5r
          inline-block
          ml-4"
    />
  );
};

export default BurgerMenu;
