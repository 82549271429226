import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { createTask, removeTask } from '../services/api';
import CompletionDisplay from './CompletionDisplay';
import SortableList from './SortableList';
import Header from './styled/Header';
import Heading from './styled/Heading';
import Justified from './styled/Justified';
import Small from './styled/Small';
import ListSection from './styled/ListSection';
import ScrollableList from './styled/ScrollableList';
import Button from './styled/Button';
import { dateFormat } from '../utils';
import { secondsToTimeLong } from '../utilities/time';
import Task from './Task';

const TaskList = React.forwardRef(({ date, tasks: initialTasks }, ref) => {
  const sumDuration = (tasks) => {
    return tasks.reduce(
      (acc, curr) => acc + (curr.completed ? 0 : curr.duration),
      0
    );
  };

  const [duration, setDuration] = useState(sumDuration(initialTasks));
  const newTaskTextareaRef = React.useRef(null);
  const [isAddTaskDisabled, setIsAddTaskDisabled] = useState(false);
  const [tasks, setTasks] = useState(initialTasks);
  const [createTaskMutation, { isLoading: isCreatingTask }] = useMutation(
    () => createTask(date),
    {
      onSuccess: (newTask) => {
        setTasks([newTask, ...tasks]);
        newTaskTextareaRef.current.focus();
        setIsAddTaskDisabled(true);
      },
      onError: () => alert('There was an error creating your task.'),
    }
  );
  const [removeTaskMutation, { isLoading: isRemovingTask }] = useMutation(
    (id) => removeTask(id),
    {
      onSuccess: (id) => {
        const newTasks = tasks.filter((task) => task.id !== id);

        setIsAddTaskDisabled(false);
        setTasks([...newTasks]);
      },
    }
  );

  const addTask = async () => {
    const untitledTasks = tasks.filter((task) => !task.title);

    if (untitledTasks.length) {
      newTaskTextareaRef.current.focus();
      return;
    }

    await createTaskMutation();
  };

  const deleteTask = async (id) => {
    if (isRemovingTask) return;

    await removeTaskMutation(id);
  };

  const updateTasks = (id, newAttributeObject) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === id) {
        const attributeName = Object.keys(newAttributeObject)[0];
        const attributeValue = Object.values(newAttributeObject)[0];
        const newTask = { ...task };

        newTask[attributeName] = attributeValue;

        return newTask;
      } else {
        return { ...task };
      }
    });

    setTasks(updatedTasks);
    return updatedTasks;
  };

  const disableAddTaskIfUntitled = (title) =>
    !title && setIsAddTaskDisabled(true);

  const toggleAddTaskButton = (title, id) => {
    if (title) {
      updateTasks(id, { title });
      setIsAddTaskDisabled(false);
    } else {
      setIsAddTaskDisabled(true);
    }
  };

  const updateDuration = (id, duration) => {
    updateTasks(id, { duration });
  };

  const updateTasksCompletion = (id, completed) => {
    const updatedTasks = updateTasks(id, { completed });
    const task = updatedTasks.find((task) => task.id === id);
    const tasksWithoutTask = updatedTasks.filter((task) => task.id !== id);

    if (completed) {
      setTasks([...tasksWithoutTask, task]);
    } else {
      setTasks([task, ...tasksWithoutTask]);
    }
  };

  useEffect(() => {
    setDuration(sumDuration(tasks));
  }, [tasks]);

  const isToday =
    new Date(date).toLocaleDateString('en-US') ===
    new Date().toLocaleDateString('en-US');

  return (
    <ListSection ref={ref}>
      <Header>
        <Heading>
          {dateFormat(date, { weekday: 'long', locale: 'en' })}
          <Small font="light"> {isToday && '(today)'}</Small>
        </Heading>
        <Justified>
          <Small>{dateFormat(date, { day: 'numeric', month: 'short' })}</Small>
          <Small>{secondsToTimeLong(duration)}</Small>
        </Justified>
        <Button
          disabled={isAddTaskDisabled || isCreatingTask || isRemovingTask}
          onClick={addTask}
        >
          Add Task
        </Button>

        <CompletionDisplay objects={tasks}></CompletionDisplay>
      </Header>

      <SortableList list={tasks} setList={setTasks} component={ScrollableList}>
        {tasks.map((task) => (
          <Task
            key={task.id}
            task={task}
            onTitleChange={toggleAddTaskButton}
            onTaskFocus={disableAddTaskIfUntitled}
            onTaskComplete={updateTasksCompletion}
            onRemoveTask={deleteTask}
            textareaRef={newTaskTextareaRef}
            setDuration={updateDuration}
          />
        ))}
      </SortableList>
    </ListSection>
  );
});

export default TaskList;
