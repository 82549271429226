import React from 'react';
import Link from './styled/Link';
import { logout } from '../services/api';
import 'twin.macro';

const LogoutButton = ({ children }) => {
  return <Link onClick={logout}>{children || 'Log Out'}</Link>;
};

export default LogoutButton;
