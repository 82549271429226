import tw from 'twin.macro';

const TopNavigationBar = tw.nav`
  w-full
  py-2 sm:py-4
  flex
  items-center justify-between
  sticky top-0
  bg-white2
  z-50
`;

export default TopNavigationBar;
