import React from 'react';
import { useState, useEffect } from 'react';
import { usePaginatedQuery, queryCache } from 'react-query';
import { getTasks } from '../services/api';
import useHorizontalMouseWheel from './useHorizontalMouseWheel';
import Article from './styled/Article';
import TaskList from './TaskList';
import Main from './styled/Main';
import { useInView } from 'react-intersection-observer';
import { simplifyDate, travelWeeks } from '../utilities/time';
import memoize from 'fast-memoize';
import Loading from './Loading';

const TaskIndex = () => {
  const keepScrollStillOnLeft = () => {
    mainRef.current.scrollLeft =
      lastRefSecondWeekBatch.current.offsetLeft -
      window.innerWidth +
      lastRefSecondWeekBatch.current.getBoundingClientRect().width;
  };
  const keepScrollStillOnRight = () => {
    mainRef.current.scrollLeft = firstRefSecondWeekBatch.current.offsetLeft;
  };
  const scrollToCurrentDayAtCenter = () => {
    mainRef.current.scrollLeft =
      currentDayRef.current.offsetLeft -
      window.innerWidth / 2 +
      currentDayRef.current.getBoundingClientRect().width / 2;
  };

  const MAX_NUMBER_OF_TASK_BATCHES = 2;
  const requests = React.useRef(0);
  const firstDate = React.useRef();
  const lastDate = React.useRef();
  const APPEND = 'append';
  const PREPEND = 'prepend';
  const direction = React.useRef();
  const currentDate = new Date();
  const [date, setDate] = useState(currentDate);
  const [tasks, setTasks] = useState(() => {
    const cache = queryCache.getQueryData(['tasks', simplifyDate(date)]);

    return cache ? [cache] : [];
  });
  const { isLoading, error } = usePaginatedQuery(
    ['tasks', simplifyDate(date)],
    () => {
      return getTasks(date);
    },
    {
      onSuccess: (data) => {
        requests.current++;
        const isFirstLoad = requests.current <= 1;

        if (direction.current === APPEND) {
          if (tasks.length >= MAX_NUMBER_OF_TASK_BATCHES) {
            setTasks([...tasks.slice(1), data]);
            keepScrollStillOnLeft();
          } else {
            setTasks([...tasks, data]);
          }
        } else if (direction.current === PREPEND) {
          if (tasks.length >= MAX_NUMBER_OF_TASK_BATCHES) {
            setTasks([data, ...tasks.slice(0, tasks.length - 1)]);
          } else {
            setTasks([data, ...tasks]);
          }

          keepScrollStillOnRight();
        } else {
          setTasks([data]);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const [firstRef, firstIsVisible] = useInView();
  const [lastRef, lastIsVisible] = useInView();

  const mainRef = React.useRef();
  const currentDayRef = React.useRef();
  const firstRefSecondWeekBatch = React.useRef();
  const firstRefFirstWeekBatch = React.useRef();
  const lastRefSecondWeekBatch = React.useRef();

  useEffect(() => {
    const isFirstLoad = requests.current <= 1;

    if (!tasks.length || !isFirstLoad) return;

    scrollToCurrentDayAtCenter();
  }, [tasks.length]);

  useEffect(() => {
    if (!tasks.length) return;

    firstDate.current = new Date(tasks[0][0][0]);
    lastDate.current = new Date(tasks.slice(-1)[0].slice(-1)[0][0]);
  }, [tasks]);

  useEffect(() => {
    if (firstIsVisible) {
      const prevWeekDate = travelWeeks(firstDate.current, -2);

      direction.current = PREPEND;
      setDate(prevWeekDate);
    }
  }, [firstIsVisible]);

  useEffect(() => {
    if (lastIsVisible) {
      const nextWeekDate = travelWeeks(lastDate.current, 2);

      direction.current = APPEND;

      setDate(nextWeekDate);
    }
  }, [lastIsVisible]);

  const setRef = React.useCallback(
    memoize((i, ii, taskListDate) => {
      return (el) => {
        if (i === 0 && ii === 0) {
          firstRef(el);
          firstRefFirstWeekBatch.current = el;
        } else if (i === 1 && ii === 0) {
          firstRefSecondWeekBatch.current = el;
        } else if (i === 0 && ii === 20) {
          lastRefSecondWeekBatch.current = el;
        } else {
          lastRef(el);
        }

        if (simplifyDate(taskListDate) === simplifyDate(currentDate)) {
          currentDayRef.current = el;
        }
      };
    }),
    []
  );

  if (isLoading)
    return (
      <Main ref={mainRef}>
        <Article>
          <Loading></Loading>
        </Article>
      </Main>
    );
  if (error) return 'An error has occurred: ' + error.message;

  return (
    <Main ref={mainRef}>
      <Article>
        {tasks.map((weekTasksData, i) => {
          return weekTasksData.map(([date, tasks], ii) => {
            return (
              <TaskList
                key={date}
                date={date}
                tasks={tasks}
                ref={setRef(i, ii, new Date(date))}
              ></TaskList>
            );
          });
        })}
      </Article>
    </Main>
  );
};

export default TaskIndex;
