import React, { forwardRef, useState } from 'react';
import FloatingMenuCard from './styled/FloatingMenuCard';
import MenuListItem from './styled/MenuListItem';
import Small from './styled/Small';
import Justified from './styled/Justified';
import Icon from './styled/Icon';
import SearchAndCreateBox from './SearchAndCreateBox';
import memoize from 'fast-memoize';

import { useQuery, useMutation, queryCache } from 'react-query';
import { updateTask } from '../services/api';
import { createCategory } from '../services/api';
import { removeCategory } from '../services/api';
import { getCategories } from '../services/api';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const CategoryMenu = forwardRef(
  (
    {
      taskId,
      onCategoryUpdate: onSuccess,
      onCategoryDelete: onDelete,
      triggerElementRef,
      containerElementRef,
    },
    ref
  ) => {
    const [categories, setCategories] = useState(
      queryCache.getQueryData('categories') || []
    );
    const [filteredCategories, setFilteredCategories] = useState(categories);

    const deleteCategoryRefs = React.useRef({});

    const { isLoading, error } = useQuery('categories', getCategories, {
      onSuccess: (data) => {
        setCategories(data);
        setFilteredCategories(data);
      },
    });
    const [updateCategoryIdMutation] = useMutation(
      (categoryId) => {
        return updateTask(taskId, { category_id: categoryId });
      },
      {
        onSuccess: (updatedCategory) => {
          const category = categories.find((category) => {
            return category.id === updatedCategory.category_id;
          });

          onSuccess(category);
        },
      }
    );
    const [findOrCreateCategoryMutation] = useMutation(
      (title) => {
        return createCategory({ title });
      },
      { onSuccess }
    );
    const [
      deleteCategoryMutation,
      { isLoading: isRemovingCategory },
    ] = useMutation((id) => removeCategory(id), {
      onSuccess: (categoryId) => {
        const deletedCategory = categories.find((category) => {
          return category.id === categoryId;
        });

        const newCategories = categories.filter(
          (category) => category.id !== categoryId
        );

        setCategories(newCategories);
        onDelete(deletedCategory);
      },
    });

    const findOrCreateCategory = async (categoryTitle) => {
      let category = categories.find((category) => {
        return category.title === categoryTitle;
      });

      if (category) {
        onSuccess(category);
      } else {
        findOrCreateCategoryMutation(categoryTitle);
      }
    };

    const filterCategories = (filterValue) => {
      const newFilteredCategories = categories.filter((category) => {
        return category.title.toLowerCase().includes(filterValue);
      });

      setFilteredCategories(newFilteredCategories);
    };

    const confirmTaskRemove = (categoryId) => {
      window.confirm('Are you sure?') && deleteCategoryMutation(categoryId);
    };

    const updateCategory = (event, category) => {
      event.persist();
      const path =
        event.nativeEvent.path ||
        (event.nativeEvent.composedPath && event.nativeEvent.composedPath());
      const clickedOnDeleteCategory =
        event.target === deleteCategoryRefs.current[category.id] ||
        path.includes(deleteCategoryRefs.current[category.id]);

      if (clickedOnDeleteCategory || isRemovingCategory) return;

      updateCategoryIdMutation(category.id);
    };

    const setDeleteCategoryRefs = React.useCallback(
      memoize((categoryId) => {
        return (node) => {
          deleteCategoryRefs.current[categoryId] =
            deleteCategoryRefs.current[categoryId] || node;
        };
      }),
      []
    );

    return (
      <FloatingMenuCard
        ref={ref}
        containerElementRef={containerElementRef}
        triggerElementRef={triggerElementRef}
        size="large"
      >
        <ul>
          <SearchAndCreateBox
            onSubmit={findOrCreateCategory}
            onSearch={filterCategories}
          ></SearchAndCreateBox>
          <MenuListItem onClick={() => updateCategoryIdMutation(null)}>
            Clear
          </MenuListItem>

          {filteredCategories.length > 0 ? (
            filteredCategories.map((category) => {
              return (
                <MenuListItem
                  onClick={(event) => updateCategory(event, category)}
                  key={category.id}
                >
                  <Justified>
                    <span>{category.title}</span>
                    <Icon
                      icon={faTrashAlt}
                      onClick={() => confirmTaskRemove(category.id)}
                      size="small"
                      color="gray"
                      colorOnHover="red"
                      ref={setDeleteCategoryRefs(category.id)}
                    ></Icon>
                  </Justified>
                </MenuListItem>
              );
            })
          ) : (
            <MenuListItem inactive>
              <Small>{isLoading ? 'Loading...' : 'No categories'}</Small>
            </MenuListItem>
          )}
        </ul>
      </FloatingMenuCard>
    );
  }
);

export default CategoryMenu;
