import tw from 'twin.macro';

const Textarea = tw.textarea`
  w-full
  resize-none

  focus:outline-none
  bg-card-white
`;

export default Textarea;
