import tw from 'twin.macro';

const SideMenu = tw.aside`
  absolute
  top-0
  bg-white
  w-full
  sm:w-3/12
  h-screen
`;

export default SideMenu;
