import React from 'react';
import SideMenu from './styled/SideMenu';
import JustifiedEnd from './styled/JustifiedEnd';
import MenuListItem from './styled/MenuListItem';
import CloseX from './styled/CloseX';
import Overlay from './styled/Overlay';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../utilities/auth';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';

const MainMenu = ({ isOpen, onClose }) => {
  return (
    <SideMenu>
      <JustifiedEnd>
        <CloseX onClick={onClose}>x</CloseX>
      </JustifiedEnd>

      <ul>
        <Link to="/tasks" onClick={onClose}>
          <MenuListItem size="large">Tasks</MenuListItem>
        </Link>

        <Link to="/weekly_goals" onClick={onClose}>
          <MenuListItem size="large">Goals</MenuListItem>
        </Link>

        {isAuthenticated() ? (
          <LogoutButton>
            <MenuListItem size="large">Log Out</MenuListItem>
          </LogoutButton>
        ) : (
          <LoginButton>
            <MenuListItem size="large">Log In</MenuListItem>
          </LoginButton>
        )}
      </ul>

      {isOpen && <Overlay onClick={onClose}></Overlay>}
    </SideMenu>
  );
};

export default MainMenu;
