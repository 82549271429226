import axios from 'axios';
import { saveRedirectTo } from '../utilities/auth';

const POST = 'POST';
const GET = 'GET';
const UNAUTHORIZED_STATUS = 401;

let baseURL;

if (process.env.REACT_APP_ENV === 'development') {
  baseURL = 'http://localhost:5000';
} else if (process.env.REACT_APP_ENV === 'staging') {
  baseURL = 'https://api.staging.planin.app';
} else if (process.env.REACT_APP_ENV === 'production') {
  baseURL = 'https://api.planin.app';
} else {
  throw new Error(
    `Missing value for process.env, got '${process.env.REACT_APP_ENV}'`
  );
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === UNAUTHORIZED_STATUS) {
      saveRedirectTo();
      login();
    }

    return Promise.reject(error);
  }
);

export const getTasks = async (date) => {
  const { data } = await axios.get('/tasks', { params: { date } });

  return data;
};

export const createTask = async (date) => {
  const { data } = await axios.post('/tasks', { date });

  return data;
};

export const updateTask = async (id, params = {}) => {
  const { data } = await axios.patch(`/tasks/${id}`, { task: params });

  return data;
};

export const removeTask = async (id) => {
  await axios.delete(`/tasks/${id}`);

  return id;
};

export const getCategories = async () => {
  const { data } = await axios.get('/categories');

  return data;
};

export const createCategory = async (title) => {
  const { data } = await axios.post('/categories', title);

  return data;
};

export const removeCategory = async (id) => {
  await axios.delete(`/categories/${id}`);

  return id;
};

const submitForm = ({ method, action }) => {
  const form = document.createElement('form');
  form.method = method;
  form.action = action;
  document.body.appendChild(form);
  form.submit();
};

export const login = async () => {
  submitForm({ method: POST, action: `${baseURL}/auth/auth0` });
};

export const logout = async () => {
  submitForm({ method: GET, action: `${baseURL}/logout` });
};
