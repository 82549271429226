import tw from 'twin.macro';

const CloseX = tw.div`
  p-2
  pr-4
  text-gray-700
  cursor-pointer
  text-xl
`;

export default CloseX;
