import tw, { styled } from 'twin.macro';

const Small = styled.span(({ size, font }) => [
  tw`text-gray-500`,
  font === 'normal' && tw`font-normal`,
  font === 'light' && tw`font-light`,
  size === 'sm' && tw`text-sm`,
  size === 'xs' && tw`text-xs`,
]);

export default Small;
