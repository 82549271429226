import React, { forwardRef } from 'react';
import FloatingMenuCard from './styled/FloatingMenuCard';
import MenuListItem from './styled/MenuListItem';
import { useMutation } from 'react-query';
import { updateTask } from '../services/api';

const DurationMenu = forwardRef(
  (
    {
      taskId,
      onDurationUpdate: onSuccess,
      triggerElementRef,
      containerElementRef,
    },
    ref
  ) => {
    const [updateDurationMutation] = useMutation(
      (duration) => updateTask(taskId, { duration }),
      {
        onSuccess,
      }
    );

    return (
      <FloatingMenuCard
        ref={ref}
        containerElementRef={containerElementRef}
        triggerElementRef={triggerElementRef}
        size="medium"
      >
        <ul>
          {[
            ['Clear', 0],
            ['5 min', 300],
            ['10 min', 600],
            ['15 min', 900],
            ['20 min', 1200],
            ['25 min', 1500],
            ['30 min', 1800],
            ['45 min', 2700],
            ['1 hr', 3600],
            ['1.5 hr', 5400],
            ['2 hr', 7200],
            ['2.5 hr', 9000],
            ['3 hr', 10800],
            ['4 hr', 14400],
            ['5 hr', 18000],
            ['6 hr', 21600],
            ['7 hr', 25200],
            ['8 hr', 28800],
          ].map(([label, durationInSeconds]) => {
            return (
              <MenuListItem
                onClick={() => updateDurationMutation(durationInSeconds)}
                key={durationInSeconds}
              >
                {label}
              </MenuListItem>
            );
          })}
        </ul>
      </FloatingMenuCard>
    );
  }
);

export default DurationMenu;
