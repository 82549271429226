import React, { useState } from 'react';
import Logo from './styled/Logo';
import BurgerMenu from './styled/BurgerMenu';
import SelectableLink from './styled/SelectableLink';
import TopNavigationLinkWrapper from './styled/TopNavigationLinkWrapper';
import TopNavigationBar from './styled/TopNavigationBar';
import TopNavigationElement from './styled/TopNavigationElement';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../utilities/auth';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import MainMenu from './MainMenu';
import HideOnMobile from './styled/HideOnMobile';

const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <TopNavigationBar>
      <TopNavigationElement>
        <BurgerMenu onClick={toggleMenu}></BurgerMenu>
        <Logo>
          <Link to="/">Planner</Link>
        </Logo>
      </TopNavigationElement>

      <TopNavigationElement>
        <TopNavigationLinkWrapper>
          <SelectableLink to="/tasks">Tasks</SelectableLink>
        </TopNavigationLinkWrapper>

        <TopNavigationLinkWrapper>
          <SelectableLink to="/weekly_goals">Goals</SelectableLink>
        </TopNavigationLinkWrapper>

        {isAuthenticated() ? (
          <HideOnMobile>
            <TopNavigationLinkWrapper last>
              <LogoutButton></LogoutButton>
            </TopNavigationLinkWrapper>
          </HideOnMobile>
        ) : (
          <HideOnMobile>
            <TopNavigationLinkWrapper last>
              <LoginButton></LoginButton>
            </TopNavigationLinkWrapper>
          </HideOnMobile>
        )}
      </TopNavigationElement>

      {isMenuOpen && (
        <MainMenu isOpen={isMenuOpen} onClose={toggleMenu}></MainMenu>
      )}
    </TopNavigationBar>
  );
};

export default Navigation;
