import React, { useLayoutEffect, useState } from 'react';
import tw, { css } from 'twin.macro';

const hideScrollBarStyles = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Main = React.forwardRef(({ children, hidden = false }, ref) => {
  const ESTIMATED_TOP_NAVIGATION_BAR_HEIGHT = 48;
  const [height, setHeight] = useState(ESTIMATED_TOP_NAVIGATION_BAR_HEIGHT);

  useLayoutEffect(() => {
    setHeight(
      ref.current?.getBoundingClientRect()?.y ||
        ESTIMATED_TOP_NAVIGATION_BAR_HEIGHT
    );
  }, [ref]);

  return (
    <main
      ref={ref}
      css={[
        tw`overflow-hidden`,
        tw`overflow-x-auto`,
        tw`h-screen`,
        hideScrollBarStyles,
      ]}
      style={{
        height: `calc(${window.innerHeight}px - ${height}px)`,
        visibility: hidden ? 'hidden' : 'visible',
      }}
    >
      {children}
    </main>
  );
});

export default Main;
