import tw, { styled } from 'twin.macro';

const yPaddings = {
  small: tw`py-2`,
  medium: tw`py-3`,
  large: tw`py-5`,
};

const MenuListItem = styled.li`
  ${({ size }) => yPaddings[size] || yPaddings['small']}
  ${tw`px-5`}
  ${({ inactive }) =>
    (inactive && [tw`hover:bg-white`, tw`cursor-default`]) || [
      tw`hover:bg-gray-200`,
      tw`cursor-pointer`,
    ]}
`;

export default MenuListItem;
