import tw, { styled } from 'twin.macro';

const Logo = styled.h1`
  ${tw`ml-4 sm:ml-12`}
  ${tw`sm:text-xl text-white text-center`}
  ${tw`px-3`}
  ${tw`inline-block`}
  ${tw`rounded-tr-xl`}
  ${tw`rounded-bl-xl`}
  ${tw`bg-blue2`}
`;

export default Logo;
