import tw from 'twin.macro';

const ScrollableList = tw.ul`
  overflow-auto
  h-screen
  pb-screen-50
  sm:pb-0
`;

export default ScrollableList;
