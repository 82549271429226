import tw from 'twin.macro';
import Section from './Section';

const ListSection = tw(Section)`
  min-w-15r max-w-15r
  sm:min-w-22r sm:max-w-22r
  px-1
`;

export default ListSection;
