import React, { useState, useLayoutEffect, useRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import 'twin.macro';

const SortableList = ({ list, setList, children, component = 'ul' }) => {
  const [height, setHeight] = useState(0);
  const targetRef = useRef();

  useLayoutEffect(() => {
    setHeight(targetRef.current?.ref?.current?.getBoundingClientRect()?.y || 0);
  }, []);

  return (
    <ReactSortable
      style={{ height: `calc(100vh - ${height}px)`, overflow: 'auto' }}
      ref={targetRef}
      tag={component}
      list={list}
      setList={setList}
      animation={150}
      delay={150}
      delayOnTouchOnly={true}
      easing="ease-in-out"
      group="list"
    >
      {children}
    </ReactSortable>
  );
};

export default SortableList;
