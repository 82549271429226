import tw from 'twin.macro';

const SearchBox = tw.input`
  border-solid border-2 border-gray-100 border-t-0 border-l-0 border-r-0
  py-1 pl-5
  w-full
  outline-none
`;

export default SearchBox;
